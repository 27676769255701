//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {rzFooter, rzIconVisa, rzIconMastercard, rzIconMir, rzIconPhoneCircle, rzIconMail, rzIconWhatsApp} from 'razlet-ui';

export default {
  name: 'footer-block',
  components: {
    rzFooter, 
    rzIconVisa, 
    rzIconMastercard, 
    rzIconMir,
    rzIconPhoneCircle,
    rzIconMail, 
    rzIconWhatsApp,
  },
  data() {
    return {
      year: (new Date()).getFullYear(),
    };
  },
};
