//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {rzContainer, rzButton, rzDropdown} from 'razlet-ui';
import enFlag from '../assets/img/en.png';
import ruFlag from '../assets/img/ru.png';

export default {
  name: 'main-header',
  components: {
    rzContainer,
    rzDropdown,
    rzButton,
  },
  data() {
    return {
      flags: {
        en: enFlag,
        ru: ruFlag,
      },
      locales: ['en', 'ru'],
      menu: [
        { label: 'Расписание', route: '/schedule' },
        { label: 'О компании', route: '/about' },
        { label: 'Условия оферты', route: '/agreement' },
      ],
      isMenu: false,
    };
  },
  methods: {
    changeLocale(locale) {
      this.$local = locale;
    },
    goHome() {
      window.location.href = '/';
    },
    openMenu() {
      this.isMenu = !this.isMenu;
    },
    goTo(route) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route });
        this.isMenu = false;
      }
    },
  },
};
