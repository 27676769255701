//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainHeader from '~/components/main-header';
import MainFooter from '~/components/main-footer';

export default {
  components: {
    MainHeader,
    MainFooter,
  },
};
